<template>

  <div id="knowledge-base" v-show="loaded">

    <!-- Header -->
    <div class="flex justify-between flex-wrap items-center">

      <!-- Search Form -->
      <vs-input class="sm:mr-4 mr-0 sm:w-5/5 w-full sm:order-normal order-3 sm:mt-0 mt-4" type="search" v-model="searchItem" placeholder="Search some theory..." />

      <!-- <vs-button color="primary" icon-pack="feather" icon="icon-plus" class="mr-4" @click="$router.push({ name: 'learning-center-create', params:{type: 'knowledge-base'} })">Add Theory</vs-button> -->

    </div>
    <div class="vx-row mt-48" v-show="category.length == 0 || !Object.keys(searchedData).length">
      <div class="vx-col w-full">
        <img
          class="block m-auto mb-base"
          :src="emptyDataImg"
          alt=""
          width="180"
          height="180"
        />
        <h4 class="text-2xl font-light text-center">Empty Category</h4>
      </div>
    </div>

    <!-- List Of Data -->
    <div class="theories">
      <div class="mt-5" v-for="(theory, i) in searchedData" :key="i">
        <div class="mt-3 mb-3">
        <div class="grid grid-cols-3 gap-4">
          <vx-card v-for="(item, i) in theory" :key="i" @click="$router.push({ name: 'learning-center-knowledge-base-theory', params: { categoryId: encrypt(item.id) } })" style="cursor: pointer;">
            <h5>{{ item.name }}</h5>
          </vx-card>
        </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import emptyDataImg from "@/assets/images/pages/search_files.svg";

export default {
  components: {
    vSelect,
  },
  data () {
    return {
      loaded: false,
      searchItem: '',
      category: [],
      emptyDataImg: emptyDataImg,
    }
  },

  computed: {
    searchedData() {
      const item = [this.category]
      return Object.keys(item).reduce((a, cKey) => {
        const data = this.filterValue(item[cKey]);
        if (data.length) {
          a[cKey] = data;
        }
        return a;
      }, {});
    }
  },
  
  methods: {
    encrypt(id) {
      return this.$secure.encrypt(id);
    },

    filterValue(content) {
      return content.filter(item => {
        return item.name.toLowerCase().includes(this.searchItem.toLowerCase())
      });
    },

    loading () {
      this.loaded = false;

      this.$vs.loading({
        type: 'border',
        color: 'primary',
        text: `Wait a minute, It's getting data.`
      })

    },

    onLoaded () {
      this.$vs.loading.close();
      this.loaded = true;
    },

    getData () {
      this.$store.dispatch('learningCenter/fetchKnowledgeBase').then((res) => {
        this.onLoaded();
        if(res.data.data.knowledge_base){
          this.category = res.data.data.knowledge_base
        }
      }).catch(err => {
        if (!err.response) {
          err.response = {
            data: {
              message: 'Server error'
            }
          };
        }

        this.$vs.notify({
          title:'Opps something error',
          text: err.response.data.message,
          color:'danger'})
      })
    },
  },
  created () {
    this.loading();
    this.getData();
  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

.vx-card {
  cursor: pointer;
}
